import React from 'react';

const SvgEuro = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2.25}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='euro_svg__feather euro_svg__feather-euro'
    {...props}
  >
    <path d='M3 10h10M3 14h8M17.5 5.5C15 3 6 2 6 12s9.5 9 11.5 6' />
  </svg>
);

export default SvgEuro;
